import httpUtil from "@/utils/httpUtil";

/**添加开发区演示 */
export const addCouldDisplayData =  params => httpUtil.post("/api/assPc/FunctionIPC/addCouldDisplayData", params);

/**开发区演示列表 */
export const queryCouldDisplayDataPage =  params => httpUtil.post("/api/assPc/FunctionIPC/queryCouldDisplayDataPage", params);

/**修改开发区演示 */
export const updaateCouldDisplayData =  params => httpUtil.post("/api/assPc/FunctionIPC/updaateCouldDisplayData", params);

/**删除开发区演示 */
export const delCouldDisplayData =  params => httpUtil.post("/api/assPc/FunctionIPC/delCouldDisplayData", params);


